import React from "react"
import styled from "styled-components"
import { Page } from "../../shared/Page/Page"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import media from "css-in-js-media"
import { Helmet } from "react-helmet"
//@ts-ignore
import ImgPat from "../../images/features/inbox/pat.jpg"
//@ts-ignore
import ImgJanet from "../../images/features/inbox/janet.jpg"
import { StaticImage } from "gatsby-plugin-image"

const Container = styled.div`
    margin-bottom: 100rem;
    section.hero {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 120rem 0 200rem;

        ${media("<tablet")} {
            padding-bottom: 80rem;
        }

        .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 600rem;

            .pre-header {
                display: flex;
                align-items: center;
                gap: 10rem;
                font-size: 14rem;
                color: ${({ theme }) => theme.text.lighter};
                font-family: "Gilroy-Regular", sans-serif;
                margin-bottom: 10rem;
            }

            h1 {
                font-size: 55rem;
                font-family: "Gilroy-Bold", sans-serif;
                color: ${({ theme }) => theme.text.dark};
                font-weight: unset;
                margin: 0;

                span {
                    background: -webkit-linear-gradient(#60a5fa, #818cf8);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            h2 {
                font-weight: normal;
                font-size: 20rem;
                color: rgb(76, 87, 115);
                line-height: 35rem;
                max-width: 490rem;
            }

            ${media("<phone")} {
                > h1:not(.desktop) {
                    font-size: 35rem;
                }

                > h2:not(.desktop) {
                    font-size: 20rem;
                }
            }
        }

        .right {
            flex: 1;
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 30rem;
            padding: 60rem;

            ${media("<tablet")} {
                display: none;
            }

            .back {
                position: absolute;
                top: 30rem;
                bottom: 30rem;
                left: 0;
                right: 0;
                margin: auto;
                width: 200rem;
                height: 200rem;
                opacity: 0;
                background: #bfdbfe;
                border-radius: 90%;
                transform-origin: center;
            }
        }
    }

    section.quick-feats {
        display: flex;
        align-items: center;
        margin: 0 -20rem;
        padding: 70rem 20rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), transparent);

        > div {
            display: flex;
            gap: 90rem;

            ${media("<desktop")} {
                > div {
                    min-width: calc(50% - 40rem);
                }

                flex-wrap: wrap;
                gap: 50rem;
            }

            ${media("<=phone")} {
                flex-direction: column;

                > div:nth-of-type(even) {
                    min-width: unset;
                    align-items: flex-end;
                    text-align: right;
                }
            }

            > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 15rem;

                svg {
                    width: 50rem;
                    height: 50rem;
                }

                .title {
                    font-size: 20rem;
                    color: ${({ theme }) => theme.text.dark};
                    font-family: "Gilroy-SemiBold", sans-serif;
                }

                .description {
                    color: ${({ theme }) => theme.text.normal};
                    font-size: 15rem;
                    line-height: 30rem;
                }
            }
        }
    }
`

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

const FeatureInBrowser = ({ data, location }) => {
    return (
        <Page
            constrainedHeader
            constrainedFooter
            constrainedCta
            location={location}
        >
            <Helmet>
                <title>Integrations and plugins</title>
            </Helmet>
            <Container>
                <section className={"hero pageConstrained"}>
                    <div className={"left"}>
                        <h1>
                            <span>Supercharge</span> your team with new{" "}
                            <span>integrations</span> and <span>powers</span>.
                        </h1>
                        <h2>
                            Plug your team into thousands of apps with our
                            Zapier and first party extensions for Hubspot and
                            more.
                        </h2>
                        <div>
                            <a href={"//app.23shout.com"} tabIndex={-1}>
                                <FlatButton color={"primary"} size={"larger"}>
                                    Get started for free <Chevron />
                                </FlatButton>
                            </a>
                        </div>
                    </div>
                    <div className={"right"}>
                        <StaticImage
                            src={"../../images/features/integrations/hero.png"}
                            alt={"Hero image of the app"}
                            objectFit={"contain"}
                            layout={"constrained"}
                            placeholder={"tracedSVG"}
                            quality={100}
                        />
                    </div>
                </section>

                {/*<section className={"quick-feats"}>*/}
                {/*    <div className={"pageConstrained"}>*/}
                {/*        <div>*/}
                {/*            <svg*/}
                {/*                xmlns="http://www.w3.org/2000/svg"*/}
                {/*                height="24px"*/}
                {/*                viewBox="0 0 24 24"*/}
                {/*                width="24px"*/}
                {/*                fill="url(#brandGradient)"*/}
                {/*            >*/}
                {/*                <rect fill="none" height="24" width="24" />*/}
                {/*                <path d="M20.54,17.73L15,11V5h1c0.55,0,1-0.45,1-1s-0.45-1-1-1H8C7.45,3,7,3.45,7,4s0.45,1,1,1h1v6l-5.54,6.73 C3.14,18.12,3,18.56,3,19c0.01,1.03,0.82,2,2,2H19c1.19,0,2-0.97,2-2C21,18.56,20.86,18.12,20.54,17.73z" />*/}
                {/*            </svg>*/}
                {/*            <div className={"title"}>Zero compromise</div>*/}
                {/*            <div className={"description"}>*/}
                {/*                Our position in the web gives us access to*/}
                {/*                faster updates and modern API's for development,*/}
                {/*                giving you a richer experience that native apps*/}
                {/*                offer.*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <svg*/}
                {/*                xmlns="http://www.w3.org/2000/svg"*/}
                {/*                height="24px"*/}
                {/*                viewBox="0 0 24 24"*/}
                {/*                width="24px"*/}
                {/*                fill="url(#brandGradient)"*/}
                {/*            >*/}
                {/*                <rect fill="none" height="24" width="24" />*/}
                {/*                <path d="M21.25,10.5c-0.41,0-0.75,0.34-0.75,0.75h-1.54c-0.15-1.37-0.69-2.63-1.52-3.65l1.09-1.09l0.01,0.01 c0.29,0.29,0.77,0.29,1.06,0s0.29-0.77,0-1.06L18.54,4.4c-0.29-0.29-0.77-0.29-1.06,0c-0.29,0.29-0.29,0.76-0.01,1.05l-1.09,1.09 c-1.02-0.82-2.27-1.36-3.64-1.51V3.5h0.01c0.41,0,0.75-0.34,0.75-0.75C13.5,2.34,13.16,2,12.75,2h-1.5c-0.41,0-0.75,0.34-0.75,0.75 c0,0.41,0.33,0.74,0.74,0.75v1.55C9.87,5.19,8.62,5.74,7.6,6.56L6.51,5.47l0.01-0.01c0.29-0.29,0.29-0.77,0-1.06 c-0.29-0.29-0.77-0.29-1.06,0L4.4,5.46c-0.29,0.29-0.29,0.77,0,1.06c0.29,0.29,0.76,0.29,1.05,0.01l1.09,1.09 c-0.82,1.02-1.36,2.26-1.5,3.63H3.5c0-0.41-0.34-0.75-0.75-0.75C2.34,10.5,2,10.84,2,11.25v1.5c0,0.41,0.34,0.75,0.75,0.75 c0.41,0,0.75-0.34,0.75-0.75h1.54c0.15,1.37,0.69,2.61,1.5,3.63l-1.09,1.09c-0.29-0.29-0.76-0.28-1.05,0.01 c-0.29,0.29-0.29,0.77,0,1.06l1.06,1.06c0.29,0.29,0.77,0.29,1.06,0c0.29-0.29,0.29-0.77,0-1.06l-0.01-0.01l1.09-1.09 c1.02,0.82,2.26,1.36,3.63,1.51v1.55c-0.41,0.01-0.74,0.34-0.74,0.75c0,0.41,0.34,0.75,0.75,0.75h1.5c0.41,0,0.75-0.34,0.75-0.75 c0-0.41-0.34-0.75-0.75-0.75h-0.01v-1.54c1.37-0.14,2.62-0.69,3.64-1.51l1.09,1.09c-0.29,0.29-0.28,0.76,0.01,1.05 c0.29,0.29,0.77,0.29,1.06,0l1.06-1.06c0.29-0.29,0.29-0.77,0-1.06c-0.29-0.29-0.77-0.29-1.06,0l-0.01,0.01l-1.09-1.09 c0.82-1.02,1.37-2.27,1.52-3.65h1.54c0,0.41,0.34,0.75,0.75,0.75c0.41,0,0.75-0.34,0.75-0.75v-1.5C22,10.84,21.66,10.5,21.25,10.5z M13.75,8c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S13.2,8,13.75,8z M12,13c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1 C13,12.55,12.55,13,12,13z M10.25,8c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S9.7,8,10.25,8z M8.5,13c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1s1,0.45,1,1C9.5,12.55,9.05,13,8.5,13z M10.25,16c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1 C11.25,15.55,10.8,16,10.25,16z M13.75,16c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C14.75,15.55,14.3,16,13.75,16z M14.5,12 c0-0.55,0.45-1,1-1s1,0.45,1,1c0,0.55-0.45,1-1,1S14.5,12.55,14.5,12z" />*/}
                {/*            </svg>*/}
                {/*            <div className={"title"}>Remote work ready</div>*/}
                {/*            <div className={"description"}>*/}
                {/*                Ideal for work from home situations in today's*/}
                {/*                world, your team needs only open 23shout on*/}
                {/*                their home computer and dive right in.*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <svg*/}
                {/*                xmlns="http://www.w3.org/2000/svg"*/}
                {/*                height="24px"*/}
                {/*                viewBox="0 0 24 24"*/}
                {/*                width="24px"*/}
                {/*                fill="url(#brandGradient)"*/}
                {/*            >*/}
                {/*                <path d="M0 0h24v24H0V0z" fill="none" />*/}
                {/*                <path d="M15.9 5c-.17 0-.32.09-.41.23l-.07.15-5.18 11.65c-.16.29-.26.61-.26.96 0 1.11.9 2.01 2.01 2.01.96 0 1.77-.68 1.96-1.59l.01-.03L16.4 5.5c0-.28-.22-.5-.5-.5zM2.06 10.06c.51.51 1.33.55 1.89.09 2.76-2.26 6.24-3.18 9.58-2.76l1.19-2.68c-4.35-.78-8.96.3-12.57 3.25-.64.53-.68 1.51-.09 2.1zm19.88 0c.59-.59.55-1.57-.1-2.1-1.36-1.11-2.86-1.95-4.44-2.53l-.53 2.82c1.13.47 2.19 1.09 3.17 1.89.58.46 1.39.43 1.9-.08zm-4.03 4.03c.6-.6.56-1.63-.14-2.12-.46-.33-.94-.61-1.44-.86l-.55 2.92c.11.07.22.14.32.22.57.4 1.33.32 1.81-.16zm-11.83-.01c.5.5 1.27.54 1.85.13.94-.66 2.01-1.06 3.1-1.22l1.28-2.88c-2.13-.06-4.28.54-6.09 1.84-.69.51-.74 1.53-.14 2.13z" />*/}
                {/*            </svg>*/}
                {/*            <div className={"title"}>*/}
                {/*                Low network requirements*/}
                {/*            </div>*/}
                {/*            <div className={"description"}>*/}
                {/*                Our head office is in Australia, we get it.*/}
                {/*                We've designed 23shout to operate even in high*/}
                {/*                latency and low bandwidth environments with*/}
                {/*                challenging network conditions.*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <svg*/}
                {/*                xmlns="http://www.w3.org/2000/svg"*/}
                {/*                height="24px"*/}
                {/*                viewBox="0 0 24 24"*/}
                {/*                width="24px"*/}
                {/*                fill="url(#brandGradient)"*/}
                {/*            >*/}
                {/*                <rect*/}
                {/*                    fill="none"*/}
                {/*                    height="24"*/}
                {/*                    width="24"*/}
                {/*                    y="0"*/}
                {/*                />*/}
                {/*                <path d="M15,3c0.55,0,1,0.45,1,1v6h1.59c0.89,0,1.34,1.08,0.71,1.71l-2.59,2.59c-0.39,0.39-1.02,0.39-1.41,0l-2.59-2.59 C11.08,11.08,11.52,10,12.41,10H14V4C14,3.45,14.45,3,15,3z M6,19.59C6,20.37,6.63,21,7.41,21h9.17c0.78,0,1.41-0.63,1.41-1.41 c0-0.72-0.44-1.03-1-1.59h3c1.1,0,2-0.9,2-2v-2c0-0.55-0.45-1-1-1s-1,0.45-1,1v2H4V5l7,0c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1L4,3 C2.9,3,2,3.9,2,5v11c0,1.1,0.9,2,2,2h3C6.45,18.55,6,18.87,6,19.59z" />*/}
                {/*            </svg>*/}
                {/*            <div className={"title"}>Faster updates</div>*/}
                {/*            <div className={"description"}>*/}
                {/*                23shout is updated twice a week, bringing new*/}
                {/*                features and experiences to market quicker than*/}
                {/*                any competitor.*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default FeatureInBrowser
